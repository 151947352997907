/* Container específico do agendamento externo */
.external-schedule-container-specific {
  max-width: 100%; /* Remove limite fixo */
  margin: 0 auto;
  padding: 1rem; /* Reduz padding no mobile */
  background-color: #1e1e1e;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .external-schedule-container-specific {
    max-width: 1280px; /* Mantém o limite apenas em telas grandes */
    padding: 2rem;
  }
}

/* Indicadores de etapas específicos */
.step-indicator-specific {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Permite que os itens quebrem linha */
  gap: 0.5rem; /* Espaçamento entre os itens */
}

.step-circle-specific {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #444;
  color: #fff;
  font-size: 0.875rem; /* Ajusta o texto */
}

.step-circle-specific.active {
  background-color: #126d50;
}

.step-line-specific {
  width: 3rem; /* Reduz largura em dispositivos menores */
  height: 0.25rem;
  background-color: #444;
}

.step-line-specific.active {
  background-color: #126d50;
}

@media (max-width: 768px) {
  .step-circle-specific {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.75rem;
  }

  .step-line-specific {
    width: 2rem; /* Reduz ainda mais a largura em telas pequenas */
  }
}

/* Styles específicos da seção de Barbeiros */
.barbers-grid,
.services-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .barbers-grid,
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .barbers-grid,
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.barber-card {
  position: relative;
  cursor: pointer;
  border-radius: var(--radius-lg);
  overflow: hidden;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.barber-card:hover {
  border-color: var(--color-border);
}

.barber-card.selected {
  border-color: rgba(95, 137, 227, 0.909);
  box-shadow: 0 4px 6px rgba(8, 22, 54, 0.1);
  transform: scale(1.02);
}

.barber-image-container {
  position: relative;
  aspect-ratio: 16/9;
}

.barber-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.barber-check {
  color: white;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(95, 137, 227, 0.909);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.575rem;
}

.barber-content {
  padding: 1rem;
  background: rgb(255, 255, 255);
}

.barber-name {
  text-align: center;
  font-size: 1.525rem;
  font-weight: 600;
  color: black;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.barber-specialty,
.barber-experience {
  font-size: 0.875rem;
  color: black;
  margin-bottom: 0.5rem;
}

/* Styles específicos da seção de Serviços */
.services-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.service-card {
  position: relative;
  cursor: pointer;
  border-radius: var(--radius-lg);
  overflow: hidden;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.service-card:hover {
  border-color: var(--color-border);
}

.service-card.selected {
  border-color: rgba(95, 137, 227, 0.909);
  box-shadow: 0 4px 6px rgba(8, 22, 54, 0.1);
  transform: scale(1.02);
}

.service-image-container {
  position: relative;
  aspect-ratio: 16/9;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-check {
  color: white;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(95, 137, 227, 0.909);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.575rem;
}

.service-content {
  padding: 1rem;
  background: rgb(255, 255, 255);
}

.service-name {
  text-align: center;
  font-size: 1.525rem;
  font-weight: 600;
  color: black;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.service-description {
  font-size: 0.875rem;
  color: black;
  margin-bottom: 1rem;
}

.service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: white;
  border-top: 1px solid var(--color-border);
}

.service-price {
  font-size: 1.125rem;
  font-weight: 600;
  color: black;
}

.service-duration {
  color: black;
}

/* Resumo dos Serviços */
.services-summary {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(228, 228, 228);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid var(--color-border);
  padding: 1rem;
  z-index: 1000;
}

.summary-container {
  display: flex;
  flex-direction: column; /* Empilha no mobile */
  gap: 0.5rem;
  text-align: center;
}

@media (min-width: 768px) {
  .summary-container {
    flex-direction: row; /* Alinha lado a lado em telas maiores */
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
}

.summary-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.summary-total,
.summary-duration {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.summary-continue-btn {
  background: #126d50;
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.summary-continue-btn:hover {
  background: #45a049;
}

@media (max-width: 768px) {
  .summary-continue-btn {
    width: 100%; /* Ocupa toda a largura no mobile */
    padding: 0.75rem;
  }
}

/* Página Inicial */
.home-container {
  text-align: center;
  padding: 2rem;
}

.home-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.home-subtitle {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
}

.home-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #126d50;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #45a049;
}

.home-features {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .home-features {
    grid-template-columns: repeat(3, 1fr);
  }
}

.home-feature-card {
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.home-feature-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.home-feature-description {
  font-size: 1rem;
  color: #666;
}
