/* Estilos Gerais */
.calendar-time-slots-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .calendar-time-slots-wrapper {
    flex-direction: row;
  }
}

.calendar-content {
  flex: 1;
  margin-right: 2rem;
}

.custom-calendar {
  width: 100%;
  max-width: 350px; /* Limitar a largura máxima do calendário */
  aspect-ratio: 1; /* Manter o calendário quadrado */
  background: #ffffff;
  border: none;
  border-radius: 16px; /* Deixar os cantos mais suaves */
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 0.1); /* Aumentar a profundidade da sombra para dar destaque */
  padding: 1.5rem; /* Mais espaçamento interno */
  margin: auto; /* Centralizar em telas menores */
}

.time-slots-container {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #2e2e2e;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.time-slots-scroll {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 1rem;
  margin-top: 1rem;
}

.time-slots-scroll ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.time-slots-scroll li {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.time-slots-scroll li:hover,
.time-slots-scroll li.selected {
  background-color: #3b82f6;
  color: white;
}

/* Ações de Agendamento */
.calendar-actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.continue-button {
  background-color: #4caf50;
  color: #ffffff;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.continue-button:hover {
  background-color: #45a049;
}

.continue-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
