.container {
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

.services-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.service-card {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.service-card:hover {
  transform: scale(1.02);
}

.service-image-container {
  width: 100%;
  aspect-ratio: 16 / 9; /* Força proporção */
  overflow: hidden;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-content {
  padding: 1rem;
}

.service-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: #333;
}

.service-description {
  font-size: 0.875rem;
  color: #666;
}

.service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-top: 1px solid #e0e0e0;
  font-size: 0.875rem;
  color: #333;
}

.service-footer .service-price {
  font-weight: bold;
}

.service-footer .service-duration {
  color: #666;
}

/* Resumo */
.services-summary {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f9f9f9;
  padding: 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #e0e0e0;
  text-align: center;
  z-index: 1000;
}

.summary-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .summary-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.summary-total,
.summary-duration {
  font-size: 1rem;
  font-weight: bold;
}

.summary-continue-btn {
  padding: 0.75rem 1.5rem;
  background: #4caf50;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.summary-continue-btn:hover {
  background: #45a049;
}
