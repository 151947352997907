.confirmation-container {
    margin-top: 2rem;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #2e2e2e;
    color: #ffffff;
    text-align: center;
  }
  
  .confirmation-details {
    margin: 1rem 0;
    text-align: left;
    display: inline-block;
  }
  
  .confirmation-details p {
    margin: 0.5rem 0;
  }
  
  .new-appointment-btn {
    background-color: #4caf50;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .new-appointment-btn:hover {
    background-color: #45a049;
  }