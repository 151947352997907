:root {
  --color-primary: #4caf50;
  --color-secondary: #1e1e1e;
  --color-bg: #1e1e1e;
  --color-text: #e0e0e0;
  --color-accent: #5f89e3;
  --color-border: #e0e0e0;
  --radius-lg: 12px;
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: var(--color-bg);
  color: var(--color-text);
}

.barbers-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  text-align: center;
}

.barbers-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-secondary);
  margin-bottom: 1.5rem;
}

.barbers-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .barbers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .barbers-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.barber-card {
  background: var(--color-bg);
  border-radius: var(--radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.barber-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.barber-image-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.barber-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.barber-card:hover .barber-image {
  transform: scale(1.05);
}

.barber-content {
  padding: 1rem;
  text-align: center;
}

.barber-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--color-secondary);
  margin-bottom: 0.5rem;
}

.barber-specialty,
.barber-experience {
  font-size: 1rem;
  color: var(--color-text);
  margin-bottom: 0.25rem;
}

.barber-specialty {
  color: var(--color-accent);
}
