.booking-form-container {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #2e2e2e;
    color: #ffffff;
  }
  
  .booking-form-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .booking-form-container input,
  .booking-form-container button {
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 4px;
  }
  
  .booking-form-container button:hover {
    background-color: #555;
  }
  